import { Component } from '@angular/core';

@Component({
  selector: 'app-pop-up-newsletter',
  templateUrl: './pop-up-newsletter.component.html',
  styleUrls: ['./pop-up-newsletter.component.css']
})
export class PopUpNewsletterComponent {

}
