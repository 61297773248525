import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  envi = environment;

  constructor() { }

  redirectToRoute(route: string) {
    if (route) {
      window.location.href = route;
    }
  }

  redirectexternalToRoute(link: string) {
    if (link) {
      window.open(link);
    }
  }
}
