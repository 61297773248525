import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  envi = environment;
  option: string;

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((val: any) => {
      // see also 
      if (val?.url) {
        this.option = val.url.substring(1);
        if(this.option === '**'){
          this.option = '';
        }
      }
    });
  }

  redirectToRoute(route: string) {
    if (route) {
      window.location.href = route;
    }
  }

  redirectexternalToRoute(link: string) {
    if (link) {
      window.open(link);
    }
  }

}
