import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'instituto-enso';

  ngOnInit() {
    if (environment.production) {
      console.log(`API Key: ${environment.apiKey}`);
    } else {
      console.log(`API Key: ${environment.apiKey}`);
    }
  }
}
