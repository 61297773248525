<div class="navbar-background">
    <div class="d-flex justify-content-center align-items-center">
        <div class="padding-navbar-options" [ngClass]="{'apply-icon': option === envi.routeInstituto}">
            <a class="nav-link font-color-primary size-options-navbar click"
                (click)="redirectToRoute(envi.routeInstituto)">O
                INSTITUTO</a>
        </div>
        <div class="padding-navbar-options" [ngClass]="{'apply-icon': option === envi.routeProfissionais}">
            <a class="nav-link font-color-primary size-options-navbar click"
                (click)="redirectToRoute(envi.routeProfissionais)">PROFISSIONAIS</a>
        </div>
        <div class="padding-navbar-options" [ngClass]="{'apply-icon': option === envi.routeProcedimentos}">
            <a class="nav-link font-color-primary size-options-navbar click"
                (click)="redirectToRoute(envi.routeProcedimentos)">PROCEDIMENTOS</a>
        </div>
        <div class="padding-navbar-options-icon">
            <a href="home">
                <img class="size-image-logo" src="../../../../assets/imgs/LOGOTIPOS ENSŌ-01-2.png" alt="imagem-logo">
            </a>
        </div>
        <div class="padding-navbar-options" [ngClass]="{'apply-icon': option === envi.routePortalPaciente}">
            <a class="nav-link float-end font-color-primary size-options-navbar click"
                (click)="redirectexternalToRoute(envi.whatsappNumber)">PORTAL DO
                PACIENTE</a>
        </div>
        <div class="padding-navbar-options" [ngClass]="{'apply-icon': option === envi.routeAgendamento}">
            <a class="nav-link float-end font-color-primary size-options-navbar click"
                (click)="redirectexternalToRoute(envi.whatsappNumber)">AGENDAMENTO</a>
        </div>
        <div class="padding-navbar-options" [ngClass]="{'apply-icon': option === envi.routeContato}">
            <a class="nav-link float-end font-color-primary size-options-navbar click"
                (click)="redirectexternalToRoute(envi.whatsappNumber)">CONTATO</a>
        </div>
    </div>
</div>